import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  // Level,
  // Tile,
  Columns
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import { Tablet, Mobile } from '../utils/presets'

class PortfolioPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Portfolio" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container className="has-text-centered">
                <Section style={{ paddingTop: '5rem' }}>
                  <Heading size={1} renderAs="h1">
                    Portfolio
                  </Heading>
                  <Heading subtitle size={4} renderAs="h2">
                    Turn ideas to apps and algorythms that <br /> add value to
                    your business
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body className="is-paddingless">
            <Container>
              <Section>
                <Columns style={{ marginTop: '1rem' }}>
                  <Columns.Column size={4}>
                    {/*<Box
                      style={{
                        position: 'relative',
                        paddingTop: '20rem',
                        backgroundColor: '#3BBFD1'
                      }}
                    >
                      <Image
                        alt="Figleaf"
                        fixed={
                          this.props.data.figleafPart.childImageSharp.fixed
                        }
                        Tag="span"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '2rem'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Figleaf
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          User personal privacy protection app ideation and POC
                          development from scratch.
                        </Heading>
                      </Section>
                    </Box>*/}
                    {/*temporary here*/}
                    <Box
                      style={{
                        position: 'relative',
                        paddingTop: '18.8999rem',
                        backgroundColor: '#3BBFD1',
                        height: '100%'
                      }}
                    >
                      <Image
                        alt="Viva Street"
                        fixed={
                          this.props.data.vivaStreetPart.childImageSharp.fixed
                        }
                        Tag="span"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '2rem'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Viva Street
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          One of the biggest classifieds web sites in France.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                  <Columns.Column size={4}>
                    <Box
                      style={{
                        position: 'relative',
                        backgroundColor: '#C370D6'
                      }}
                    >
                      <Image
                        alt="Fitness App"
                        fixed={this.props.data.fitnessApp.childImageSharp.fixed}
                        Tag="span"
                        style={{
                          position: 'relative',
                          margin: '0 auto',
                          display: 'flex'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Fitness App
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          ML-powered fitness app that shows what shape the user
                          can achieve in reality by body photo.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                  <Columns.Column size={4}>
                    <Box
                      style={{
                        position: 'relative',
                        paddingTop: '20rem',
                        backgroundColor: '#5B59FB'
                      }}
                    >
                      <Image
                        alt="Snipper App"
                        fixed={
                          this.props.data.snipperPart.childImageSharp.fixed
                        }
                        Tag="span"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '2rem'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Snipper App
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          SnipperApp is a next-generation code snippets manager,
                          built exclusively for macOS.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                  <Columns.Column size={12 /*8*/}>
                    <Box
                      style={{
                        position: 'relative',
                        backgroundColor: '#11B2F7',
                        paddingTop: '19rem'
                      }}
                    >
                      <Image
                        alt="Ocean.io"
                        fixed={this.props.data.oceanIO.childImageSharp.fixed}
                        Tag="span"
                        css={{
                          position: 'absolute !important',
                          // right: '5rem',
                          [Mobile]: {
                            right: '1rem',
                            top: '2.5rem'
                          },
                          [Tablet]: {
                            right: '12rem',
                            top: '2.5rem'
                          }
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Ocean.io
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          Growth Intelligence platform. Helps to find prospects,
                          competitors and improve marketing and sales team
                          performance.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                  {/*<Columns.Column size={4}>
                  <Box
                      style={{
                        position: 'relative',
                        paddingTop: '18.8999rem',
                        backgroundColor: '#3BBFD1'
                      }}
                    >
                      <Image
                        alt="Viva Street"
                        fixed={
                          this.props.data.vivaStreetPart.childImageSharp.fixed
                        }
                        Tag="span"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '2rem'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Viva Street
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          One of the biggest classifieds web sites in France.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>*/}
                  <Columns.Column size={4}>
                    <Box
                      style={{
                        position: 'relative',
                        backgroundColor: '#E0447C'
                      }}
                    >
                      <Image
                        alt="Russian Guru"
                        fixed={
                          this.props.data.russianGuru.childImageSharp.fixed
                        }
                        Tag="span"
                        style={{
                          position: 'relative',
                          margin: '2rem auto 0 auto',
                          display: 'flex'
                        }}
                      />
                      <Section>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Russian Guru
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          With Russian Guru you can master the Russian cases by
                          quizzing yourself on accusative, dative, genitive,
                          prepositional, and instrumental cases.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                  <Columns.Column size={8}>
                    <Box
                      style={{
                        position: 'relative',
                        backgroundColor: '#5B59FB',
                        height: '100%'
                      }}
                    >
                      <Image
                        alt="Hotwire.com"
                        fixed={this.props.data.hotWire.childImageSharp.fixed}
                        Tag="span"
                        style={{
                          position: 'relative',
                          margin: '2rem 3rem 1rem auto',
                          display: 'flex'
                        }}
                      />
                      <Section style={{ padding: '3.9rem 1.5rem' }}>
                        <Heading
                          size={4}
                          renderAs="h2"
                          style={{ color: '#fff' }}
                        >
                          Hotwire.com
                        </Heading>
                        <Heading
                          subtitle
                          size={6}
                          renderAs="h3"
                          style={{ color: '#fff' }}
                        >
                          Hotwire is a travel website that offers airline
                          tickets, hotel rooms, rental cars, and vacation
                          packages.
                        </Heading>
                      </Section>
                    </Box>
                  </Columns.Column>
                </Columns>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

PortfolioPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default PortfolioPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    figleafPart: file(absolutePath: { regex: "/figleaf-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 265, height: 296, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fitnessApp: file(absolutePath: { regex: "/fitness-app-big@2x.png/" }) {
      childImageSharp {
        fixed(width: 244, height: 302, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    snipperPart: file(absolutePath: { regex: "/snipper-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 188, height: 260, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    oceanIO: file(absolutePath: { regex: "/ocean-io-big@2x.png/" }) {
      childImageSharp {
        fixed(width: 418, height: 324, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    vivaStreetPart: file(absolutePath: { regex: "/viva-street-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 287, height: 274, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    russianGuru: file(absolutePath: { regex: "/russian-guru-full@2x.png/" }) {
      childImageSharp {
        fixed(width: 244, height: 302, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hotWire: file(absolutePath: { regex: "/hotwire-big@2x.png/" }) {
      childImageSharp {
        fixed(width: 493, height: 296, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
